import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RootStateType } from '../../../../store/reducers/root-reducer';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
	CompanyInterface,
	editCompany,
	setCompaniesErrors,
} from '../../../../store/slices/companies.slice';
import EditCompanyDetailsSectionInterface from './edit-company-details-section.interface';
import EditableViewForm from '../../editable-view-form';
import InputWithFloatLabel from '../../form-elemets/input-with-float-label/InputWithFloatLabel';
import { getErrorTargetByName, transformNumericFieldsOfPayload } from '../../../../helpers';
import useActiveCompany from '../../../../helpers/hooks/use-active-company/useActiveCompany';
import SelectWithFloatLabel from '../../form-elemets/select-with-float-label/SelectWithFloatLabel';

import styles from './EditCompanyDetailsSection.module.scss';
import { getYearsFromCurrent } from '../../../../helpers/date';
import OptionInterface from '../../../atoms/form/option/option.interface';

const EditCompanyDetailsSection: FC<EditCompanyDetailsSectionInterface> = () => {
	const { register, handleSubmit, errors, clearErrors } = useForm();
	const dispatch = useDispatch();
	const [editModeState, setEditModeState] = useState<boolean>();
	const [nameState, setNameState] = useState<string | undefined>();
	const [sizeState, setSizeState] = useState<string | undefined>();
	const [yearFoundedState, setYearFoundedState] = useState<number | string | undefined>();
	const [websiteUrlState, setWebsiteUrlState] = useState<number | string | undefined>();
	const [instagramUrlState, setInstagramUrlState] = useState<number | string | undefined>();
	const [facebookUrlState, setFacebookUrlState] = useState<number | string | undefined>();
	const [twitterUrlState, setTwitterUrlState] = useState<number | string | undefined>();
	const [linkedInUrlState, setLinkedInUrlState] = useState<number | string | undefined>();
	const [glassdoorUrlState, setGlassdoorUrlState] = useState<number | string | undefined>();
	const [cancelState, setCancelState] = useState<boolean>(true);
	const {
		companies: { activeCompanyId, items, loading, errors: apiErrors },
	} = useSelector((state: RootStateType) => state);
	const activeCompany = useActiveCompany(activeCompanyId, items);
	const companySizeOptions = useMemo(
		() => [
			{
				text: ' 0-50',
			},
			{
				text: '51-200',
			},
			{
				text: '201-500',
			},
			{
				text: '501-1,000',
			},
			{
				text: '1,001-5,000',
			},
			{
				text: '5,000-10,000',
			},
			{
				text: '10,001+',
				value: '10,001',
			},
		],
		[],
	);

	const years = useMemo(() => getYearsFromCurrent(), []);

	const getOptions = useMemo((): OptionInterface[] => {
		return (
			years &&
			years.map((item: any) => ({
				value: item.toString(),
				text: item.toString(),
			}))
		);
	}, [years]);

	const nameError = useMemo(
		() => getErrorTargetByName({ targets: apiErrors, name: 'name' }),
		[apiErrors],
	);
	const sizeError = useMemo(
		() => getErrorTargetByName({ targets: apiErrors, name: 'size' }),
		[apiErrors],
	);
	const yearFoundedError = useMemo(
		() => getErrorTargetByName({ targets: apiErrors, name: 'yearFounded' }),
		[apiErrors],
	);
	const websiteUrlError = useMemo(
		() => getErrorTargetByName({ targets: apiErrors, name: 'websiteUrl' }),
		[apiErrors],
	);
	const twitterUrlError = useMemo(
		() => getErrorTargetByName({ targets: apiErrors, name: 'twitterUrl' }),
		[apiErrors],
	);
	const linkedInUrlError = useMemo(
		() => getErrorTargetByName({ targets: apiErrors, name: 'linkedInUrl' }),
		[apiErrors],
	);
	const instagramUrlError = useMemo(
		() => getErrorTargetByName({ targets: apiErrors, name: 'instagramUrl' }),
		[apiErrors],
	);
	const facebookUrlError = useMemo(
		() => getErrorTargetByName({ targets: apiErrors, name: 'facebookUrl' }),
		[apiErrors],
	);
	const glassdoorUrlError = useMemo(
		() => getErrorTargetByName({ targets: apiErrors, name: 'glassdoorUrl' }),
		[apiErrors],
	);

	useEffect(() => {
		if (activeCompany) {
			setNameState(activeCompany.name);
			setYearFoundedState(activeCompany.yearFounded);
			setSizeState(activeCompany.size);
			setWebsiteUrlState(activeCompany.websiteUrl);
			setFacebookUrlState(activeCompany.facebookUrl);
			setInstagramUrlState(activeCompany.instagramUrl);
			setLinkedInUrlState(activeCompany.linkedInUrl);
			setTwitterUrlState(activeCompany.twitterUrl);
			setGlassdoorUrlState(activeCompany.glassdoorUrl);

			clearErrors();
			dispatch(setCompaniesErrors([]));
		}
	}, [activeCompany, cancelState, clearErrors, dispatch]);

	const onSubmit = useCallback(
		(data: Partial<CompanyInterface>) => {
			setEditModeState(true);

			if (activeCompany) {
				const normalizedData = transformNumericFieldsOfPayload(data, ['yearFounded']);

				dispatch<any>(editCompany(activeCompany.id, normalizedData)).then((data: any) => {
					if (!data?.errors) {
						setEditModeState(false);
					}
				});
			}
		},
		[activeCompany, dispatch],
	);

	return (
		<EditableViewForm
			{...{ editModeState, loading }}
			onSubmit={handleSubmit(onSubmit)}
			afterModeChange={setCancelState}
			sectionTitle="Company Details"
			formClassName={styles.form}
		>
			<InputWithFloatLabel
				className={classNames('form-item')}
				minWidth="calc(50% - 32px)"
				maxWidth="calc(50% - 32px)"
				id="name"
				ref={register({
					required: { value: true, message: 'name field is required' },
				})}
				visibleDisableStyles={false}
				label="Company name"
				error={errors?.name?.message || (nameError && Object.values(nameError.constraints))}
				inputSettings={{
					name: 'name',
					value: cancelState ? activeCompany?.name || '' : nameState || '',
					onChange: (event) => {
						setNameState(event.target.value);
					},
				}}
			/>

			<SelectWithFloatLabel
				className={classNames('form-item')}
				minWidth="calc(50% - 32px)"
				maxWidth="calc(50% - 32px)"
				id="size"
				ref={register()}
				visibleDisableStyles={false}
				label="Employees"
				error={sizeError ? Object.values(sizeError.constraints) : ''}
				selectSettings={{
					options: companySizeOptions,
					name: 'size',
					value: cancelState ? activeCompany?.size : sizeState,
					onChange: (event) => {
						setSizeState(event.target.value);
					},
				}}
			/>

			<SelectWithFloatLabel
				className={classNames('form-item')}
				minWidth="calc(50% - 32px)"
				maxWidth="calc(50% - 32px)"
				id="year-founded"
				ref={register()}
				visibleDisableStyles={false}
				error={yearFoundedError ? Object.values(yearFoundedError.constraints) : ''}
				label="Founded"
				selectSettings={{
					name: 'yearFounded',
					options: getOptions,
					value: cancelState ? activeCompany?.yearFounded : yearFoundedState,
					onChange: (event) => {
						setYearFoundedState(event.target.value);
					},
				}}
			/>

			<InputWithFloatLabel
				className={classNames('form-item')}
				minWidth="calc(50% - 32px)"
				maxWidth="calc(50% - 32px)"
				id="website-url"
				ref={register()}
				visibleDisableStyles={false}
				error={websiteUrlError ? Object.values(websiteUrlError.constraints) : ''}
				label="Website"
				inputSettings={{
					name: 'websiteUrl',
					value: cancelState ? activeCompany?.websiteUrl || '' : websiteUrlState || '',
					onChange: (event) => {
						setWebsiteUrlState(event.target.value);
					},
				}}
			/>

			<InputWithFloatLabel
				className={classNames('form-item')}
				minWidth="calc(50% - 32px)"
				maxWidth="calc(50% - 32px)"
				id="instagram"
				ref={register()}
				visibleDisableStyles={false}
				label="Instagram"
				error={instagramUrlError ? Object.values(instagramUrlError.constraints) : ''}
				inputSettings={{
					name: 'instagramUrl',
					value: cancelState
						? activeCompany?.instagramUrl || ''
						: instagramUrlState || '',
					onChange: (event) => {
						setInstagramUrlState(event.target.value);
					},
				}}
			/>

			<InputWithFloatLabel
				className={classNames('form-item')}
				minWidth="calc(50% - 32px)"
				maxWidth="calc(50% - 32px)"
				id="facebook"
				ref={register()}
				visibleDisableStyles={false}
				label="Facebook"
				error={facebookUrlError ? Object.values(facebookUrlError.constraints) : ''}
				inputSettings={{
					name: 'facebookUrl',
					value: cancelState ? activeCompany?.facebookUrl || '' : facebookUrlState || '',
					onChange: (event) => {
						setFacebookUrlState(event.target.value);
					},
				}}
			/>

			<InputWithFloatLabel
				className={classNames('form-item')}
				minWidth="calc(50% - 32px)"
				maxWidth="calc(50% - 32px)"
				id="twitter"
				ref={register()}
				visibleDisableStyles={false}
				label="X (formerly Twitter)"
				error={twitterUrlError ? Object.values(twitterUrlError.constraints) : ''}
				inputSettings={{
					name: 'twitterUrl',
					value: cancelState ? activeCompany?.twitterUrl || '' : twitterUrlState || '',
					onChange: (event) => {
						setTwitterUrlState(event.target.value);
					},
				}}
			/>

			<InputWithFloatLabel
				className={classNames('form-item')}
				minWidth="calc(50% - 32px)"
				maxWidth="calc(50% - 32px)"
				id="linkedIn"
				ref={register()}
				visibleDisableStyles={false}
				label="Linkedin"
				error={linkedInUrlError ? Object.values(linkedInUrlError.constraints) : ''}
				inputSettings={{
					name: 'linkedInUrl',
					value: cancelState ? activeCompany?.linkedInUrl || '' : linkedInUrlState || '',
					onChange: (event) => {
						setLinkedInUrlState(event.target.value);
					},
				}}
			/>

			<InputWithFloatLabel
				className={classNames('form-item')}
				minWidth="calc(50% - 32px)"
				maxWidth="calc(50% - 32px)"
				id="glassdoor"
				ref={register()}
				visibleDisableStyles={false}
				label="Glassdoor"
				error={glassdoorUrlError ? Object.values(glassdoorUrlError.constraints) : ''}
				inputSettings={{
					name: 'glassdoorUrl',
					value: cancelState ? activeCompany?.glassdoorUrl || '' : glassdoorUrlState || '',
					onChange: (event) => {
						setGlassdoorUrlState(event.target.value);
					},
				}}
			/>

		</EditableViewForm>
	);
};

export default React.memo(EditCompanyDetailsSection);
