import React, { FC, useCallback, useMemo } from 'react';

import styles from './ResumeScreen.module.scss';
import ResumeScreenInterface from './resume-screen.interface';
import TypographyText from '../../../atoms/ui/typography-text';
import Title from '../../../atoms/title/Title';
import CandidateExperienceList from '../../../molecules/custom/candidate-experience-list/CandidateExperienceList';
import { PREVIEW_RESUME_FILE_EXTENSIONS } from '../../../../helpers/custom/validation';
import useCandidate from '../../../../helpers/hooks/use-candidate/useCandidate';
import { getFileExtension, getFileType } from '../../../../helpers/custom/common';
import classNames from 'classnames';
import useDevice from '../../../../helpers/hooks/use-device';
import { FeatureFlagKeys } from '../../../../helpers/constants';
import useFeatureFlagValue from '../../../../helpers/hooks/use-feature-flag-value';

const ResumeScreen: FC<ResumeScreenInterface> = ({ candidateWorkHistory }) => {
	const { candidateInfo } = useCandidate();
	const { resumeUrl, convertedResumeUrl } = candidateInfo || {};
	const device = useDevice();

	const displayCandidateSummary = useFeatureFlagValue(FeatureFlagKeys.displayCandidateSummary);

	const candidateHasResume = useMemo(() => !!resumeUrl, [resumeUrl]);
	const candidateHasConvertedResume = useMemo(() => !!convertedResumeUrl, [convertedResumeUrl]);

	const resumePreviewAvailable = useCallback(() => {
		if (candidateHasConvertedResume) return candidateHasConvertedResume;

		const fileExtension = getFileExtension(resumeUrl);
		return PREVIEW_RESUME_FILE_EXTENSIONS.includes(fileExtension as string);
	}, [candidateHasConvertedResume, resumeUrl]);

	const renderResumePreview = useMemo(() => {
		const resumeToRender = convertedResumeUrl || resumeUrl;
		const fileType = getFileType(resumeToRender);

		const heightForResume = device === 'mobile' ? '600px' : '1200px';

		return (
			<object
				className={styles.preview}
				width={fileType.startsWith('application') ? '100%' : 'auto'}
				height={fileType.startsWith('application') ? heightForResume : 'auto'}
				data={resumeToRender}
				type={fileType}
			></object>
		);
	}, [convertedResumeUrl, device, resumeUrl]);

	const renderResumeStub = useMemo(() => {
		return (
			<div className={styles.stub}>
				<div className={styles.section}>
					<TypographyText className={styles.title}>
						{candidateHasResume
							? 'Preview is not available for this resume format.'
							: 'Candidate did not provide a resume.'}
					</TypographyText>
				</div>

				{candidateWorkHistory?.length ? (
					<div className={styles.section}>
						<Title
							component="h3"
							fontSize={22}
							marginBottom={16}
							fontWeight={600}
							className={styles['answer-section__title']}
						>
							Experience
						</Title>

						<CandidateExperienceList
							candidateWorkHistory={candidateWorkHistory}
							minHeight={20}
						/>
					</div>
				) : null}
			</div>
		);
	}, [candidateHasResume, candidateWorkHistory]);

	const convertedResumeWarning = useMemo(() => {
		if (candidateHasConvertedResume)
			return (
				<div className={styles.warning}>
					<div className={classNames(styles.highlighted, styles.text)}>
						This resume was converted from DOC/DOCX format.
					</div>

					<div className={styles.text}>
						If the resume is not displaying correctly, you can{' '}
						<a download className={styles.link} href={resumeUrl}>
							download it
						</a>
						.
					</div>
				</div>
			);
	}, [candidateHasConvertedResume, resumeUrl]);

	return (
		<div className={styles.resume}>
			
			{displayCandidateSummary && candidateInfo?.summary && (
				<div className={styles.summary}>
					<span className={styles['summary__text']}>
						{candidateInfo?.summary}
					</span>
				</div>
			)}

			<div className={styles['preview-section']}>
				{candidateHasConvertedResume || (candidateHasResume && resumePreviewAvailable())
					? renderResumePreview
					: renderResumeStub}
			</div>

			{convertedResumeWarning}
		</div>
	);
};

export default React.memo(ResumeScreen);
