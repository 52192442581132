import { GrowthBook } from '@growthbook/growthbook-react';
import { useEffect, useState } from 'react';

const useFeatureFlagsClient = () => {
	const env = process.env.REACT_APP_ENV;
	const clientId = process.env.REACT_APP_FEATURE_FLAGS_CLIENT_ID;
	const [client, setClient] = useState<any>();

	useEffect(() => {
		if (!client && clientId) {
			setClient(
				new GrowthBook({
					apiHost: 'https://cdn.growthbook.io',
					clientKey: clientId,
					enableDevMode: env !== 'production', //to use the Chrome DevTools Extension
					backgroundSync: false,
				}),
			);
		}

		if (client) {
			client?.loadFeatures();
		}
	}, [clientId, env, client]);

	return client;
};

export default useFeatureFlagsClient;
