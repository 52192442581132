export interface FeatureFlagContextData {
	companyId?: string;
}

const sendFeatureFlagsContext = (client: any, contextData: FeatureFlagContextData | null) => {
	if (client && contextData && Object.keys(contextData).length) {
		const {
			companyId,
		} = contextData;

		client.setAttributes({
			...(companyId && { companyId }),
		});
	}
};

export default sendFeatureFlagsContext;
