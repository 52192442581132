import React, { useEffect, useMemo, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { BaseRouteInterface } from './helpers/routes/routes.interface';
import { FilterProvider } from './contexts/filter-context';
import { PaginationProvider } from './contexts/pagination-context';
import { DomainNameProvider } from './contexts/domain-name-context';
import { ResizeDeviceProvider } from './contexts/resize-device-context';
import { routes, getDomainName, PublicRoute, PrivateRoute } from './helpers';
import { getUserInfo } from './store/slices/user.slice';
import useDevice from './helpers/hooks/use-device';
import useFilterParams from './helpers/hooks/use-filter-params';
import usePaginationParams from './helpers/hooks/use-pagination-params';

import './App.scss';
import { UtmParamsContextProvider } from './contexts/utm-params-context';
import { loginRedirectionPathReducer } from './store/reducers/login-redirection-path-reducer';
import { LoginRedirectionProvider } from './contexts/login-redirection-context';
import Loader from './components/molecules/loader';
import { AnimatePresence } from 'framer-motion/dist/framer-motion';
import { APP_URLS } from './helpers/routes/routes';
import useUser from './helpers/hooks/use-user/useUser';
import { StorageItemKeys } from './helpers/constants';
import { useUpdateActiveCompanyInStorage } from './helpers/hooks/custom/use-company-from-url/useCompanyFromUrl';
import useFeatureFlagsClient from './helpers/hooks/use-feature-flags-client';
import { GrowthBookProvider } from '@growthbook/growthbook-react';

function App() {
	const dispatch = useDispatch();
	const location = useLocation();
	const activeDevice = useDevice();

	const { authorized, loading } = useUser();
	const { state: filterParamsState, dispatch: dispatchFilterParams } = useFilterParams();
	const { state: paginationParamsState, dispatch: dispatchPaginationParams } =
		usePaginationParams();

	const domainEnv = useMemo(() => getDomainName(), []);

	const growthbook = useFeatureFlagsClient();

	const [loginRedirectionParamsState, dispatchLoginRedirectionParams] = useReducer(
		loginRedirectionPathReducer,
		{ pathname: null, search: null },
	);

	const AppRoutes = useMemo(() => {
		return routes.map(({ type, ...rest }: BaseRouteInterface) => {
			if (type === 'public') {
				return <Route path={rest.path} element={<PublicRoute {...rest} />} />;
			}

			if (type === 'private') {
				return <Route path={rest.path} element={<PrivateRoute {...rest} />} />;
			}
		});
	}, []);

	useUpdateActiveCompanyInStorage();

	useEffect(() => {
		// @ts-ignore
		process.dispatch = dispatch;

		if (authorized) {
			dispatch(getUserInfo());
		}
	}, [authorized, dispatch, location.search]);

	return (
		<GrowthBookProvider growthbook={growthbook}>
			<DomainNameProvider value={domainEnv}>
				{loading ? <Loader wide /> : null}

				<LoginRedirectionProvider
					value={{
						state: loginRedirectionParamsState,
						dispatch: dispatchLoginRedirectionParams,
					}}
				>
					<PaginationProvider
						value={{
							state: paginationParamsState,
							dispatch: dispatchPaginationParams,
						}}
					>
						<UtmParamsContextProvider
							value={{
								state: {
									utmSource: sessionStorage?.getItem(StorageItemKeys.originTag),
								},
							}}
						>
							<ResizeDeviceProvider value={activeDevice}>
								<FilterProvider
									value={{
										state: filterParamsState,
										dispatch: dispatchFilterParams,
									}}
								>
									<AnimatePresence mode="wait">
										<Routes location={location} key={location.pathname}>
											{AppRoutes}

											{!authorized ? (
												<Route
													path={'*'}
													element={<Navigate to={APP_URLS.login} replace />}
												/>
											) : (
												<>
													<Route
														path={'/'}
														element={
															<Navigate to={APP_URLS.jobs} replace />
														}
													/>

													<Route
														path={'*'}
														element={
															<Navigate to={APP_URLS[404]} replace />
														}
													/>
												</>
											)}
										</Routes>
									</AnimatePresence>
								</FilterProvider>
							</ResizeDeviceProvider>
						</UtmParamsContextProvider>
					</PaginationProvider>
				</LoginRedirectionProvider>
			</DomainNameProvider>
		</GrowthBookProvider>
	);
}

export default React.memo(App);
