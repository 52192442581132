import { Dispatch } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FacilityInterface } from './facilities.slice';
import companiesApi from '../../api/companies.api';
import { ErrorType } from '../../helpers/forms/forms';
import { RootStateType } from '../reducers/root-reducer';
import {
	checkIfCompanyCanBoost,
	checkIfCompanyCanSeeAnalytics,
	checkIfCompanyCanSeeBilling,
	checkIfCompanyHasPaymentTransactions,
	formatStringAsAbsDecimal,
} from '../../helpers/custom/common';
import { UserCoreInfoType } from './user.slice';
import { StorageItemKeys } from '../../helpers/constants';

export interface BoostHistoryRecord {
	jobId: number;
	jobName: string;
	location: string;
	amount: string;
	user: Partial<UserCoreInfoType> | null;
	boostDate: {
		startedAt: string;
		endedAt?: string;
	};
}

export interface CreditsHistoryRecord {
	id: number;
	createdAt: string;
	type: string;
	credits: string;
	amount: string;
	companyId: string;
	user: UserCoreInfoType | null;
}

export interface JobPaymentsHistoryRecord {
	id: number;
	createdAt: string;
	type: string;
	amount: string;
	companyId: string;
	user: UserCoreInfoType | null;
}

export interface CreditsRefillInterface {
	productId: number;
	type?: string;
}

export interface CompaniesSliceInterface {
	errors: ErrorType[];
	imageLoading: { target: string; remove?: boolean }[];
	loading?: boolean;
	activeCompanyId?: string | number;
	activeCompanyStatistic?: CompanyStatisticInterface;
	activeCompanyBalance?: number;
	activeCompanyPrepaidJobsBalance?: number;
	activeCompanyCanBoost?: boolean;
	activeCompanyCanSeeAnalytics?: boolean;
	activeCompanyCanSeeBilling?: boolean;
	activeCompanyHasPaymentTransactions?: boolean;
	items?: CompanyInterface[];
	creditsRefillLoading?: boolean;
	balanceUpdateLoading?: boolean;
	prepaidJobsBalanceUpdateLoading?: boolean;
}

export interface CompanyStatisticInterface {
	activeJobsCount: string;
	applicationsCount: string;
	jobsCount: string;
	relevantApplicationsCount: number;
	facilitiesCount: number;
}

export type EmailTemplateType = {
	id: string;
	label: string;
	subject: string;
	body: string;
	companyId?: string;
};

export type MessagingDataType = {
	applicationId: number | string;
	uniqueName: string;
	subject: string;
	body: string;
};

export interface CompanyInterface {
	allowsCannabis?: boolean;
	id: string;
	name: string;
	description: string;
	yearFounded: number;
	websiteUrl: string;
	logoUrl: string;
	videoUrl: string;
	linkedInUrl: string;
	facebookUrl: string;
	instagramUrl: string;
	twitterUrl: string;
	glassdoorUrl: string;
	emailLogoUrl: string;
	benefits: string;
	eoe: string;
	facilities?: FacilityInterface[];
	image1Url: string;
	image2Url: string;
	image3Url: string;
	size: string;
	testCompany: boolean;
	hasWurk?: boolean;
	ogImageHeight?: string;
	ogImageWidth?: string;
	ogImageType?: string;
	ogImageUrl?: string;
	defaultJobBoardUrl?: string;
	sendEmailOnRejection?: boolean;
	hasExternalJobs?: boolean;
	balance?: string;
	isAor?: boolean;
	jobBalance?: number;
	plan?: {
		name: string;
		uniqueName: string;
		isBoostAllowed: boolean;
	};
}

const initialState: CompaniesSliceInterface = {
	loading: false,
	imageLoading: [],
	errors: [],
	creditsRefillLoading: false,
	balanceUpdateLoading: false,
	activeCompanyBalance: 0,
};

export interface CreateCompanyInterface {
	name: string;
	websiteUrl?: string;
	userTitle?: string;
	hiringNeeds?: number;
	hasATS?: number;
	referral?: number;
	originTag?: string | null;
	allowsCannabis?: boolean;
	size?: string;
}

export const companiesSlice = createSlice({
	name: 'companies',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
		},
		loaded: (state) => {
			state.loading = false;
		},
		setCompaniesErrors: (state, { payload }: PayloadAction<any[]>) => {
			state.errors = payload;
		},
		imageLoading: (
			state,
			{
				payload,
			}: PayloadAction<{
				target: string;
				remove?: boolean;
			}>,
		) => {
			if (payload.remove) {
				state.imageLoading = state.imageLoading.filter(
					(item) => item.target !== payload.target,
				);
			} else {
				state.imageLoading = [...state.imageLoading, { target: payload.target }];
			}
		},
		creditsRefillLoading(state, { payload }: PayloadAction<boolean>) {
			state.creditsRefillLoading = payload;
		},
		balanceUpdateLoading(state, { payload }: PayloadAction<boolean>) {
			state.balanceUpdateLoading = payload;
		},
		prepaidJobsBalanceUpdateLoading(state, { payload }: PayloadAction<boolean>) {
			state.prepaidJobsBalanceUpdateLoading = payload;
		},
		getAvailableCompaniesSuccess: (state, { payload }: PayloadAction<CompanyInterface[]>) => {
			let activeCompany: { id?: string; jobBalance?: number } = {};
			const cachedActiveCompany = localStorage.getItem(StorageItemKeys.activeCompany);

			if (payload.length === 1) activeCompany = payload[0];
			else if (cachedActiveCompany) {
				const parsedCompany = JSON.parse(cachedActiveCompany);

				if (payload && parsedCompany.id) {
					const userCompany = payload.find((p) => p.id === parsedCompany.id);

					activeCompany = userCompany ? userCompany : payload[0];
				} else activeCompany = payload[0];
			} else activeCompany = payload[0];

			state.items = payload;
			state.activeCompanyId = activeCompany.id;
			state.activeCompanyCanBoost = checkIfCompanyCanBoost(activeCompany);
			state.activeCompanyCanSeeAnalytics = checkIfCompanyCanSeeAnalytics(activeCompany);
			state.activeCompanyCanSeeBilling = checkIfCompanyCanSeeBilling(activeCompany);
			state.activeCompanyHasPaymentTransactions =
				checkIfCompanyHasPaymentTransactions(activeCompany);
			state.activeCompanyPrepaidJobsBalance = activeCompany.jobBalance;

			if (activeCompany.id)
				localStorage.setItem(
					StorageItemKeys.activeCompany,
					JSON.stringify({ id: activeCompany.id }),
				);
		},
		getAvailableCompaniesFailed: (state) => {
			state.items = [];
		},
		getCompanyStatisticSuccess: (
			state,
			{ payload }: PayloadAction<CompanyStatisticInterface>,
		) => {
			state.activeCompanyStatistic = payload;
			state.loading = false;
		},
		getCompanyStatisticFailed: (state, action) => {
			state.loading = false;
			console.log(action);
		},
		getCompanyBalanceSuccess: (state, { payload }: PayloadAction<number>) => {
			state.activeCompanyBalance = payload;
		},
		getCompanyPrepaidJobsBalanceSuccess: (state, { payload }: PayloadAction<number>) => {
			state.activeCompanyPrepaidJobsBalance = payload;
		},
		setActiveCompany: (state, { payload }) => {
			localStorage.setItem(StorageItemKeys.activeCompany, JSON.stringify({ id: payload.id }));
			state.activeCompanyId = payload.id;
			state.activeCompanyCanBoost = checkIfCompanyCanBoost(payload);
			state.activeCompanyCanSeeAnalytics = checkIfCompanyCanSeeAnalytics(payload);
			state.activeCompanyCanSeeBilling = checkIfCompanyCanSeeBilling(payload);
			state.activeCompanyHasPaymentTransactions =
				checkIfCompanyHasPaymentTransactions(payload);
			state.activeCompanyPrepaidJobsBalance = payload.jobBalance;
		},
		clearActiveCompany: (state) => {
			state.activeCompanyId = undefined;
		},
		clearCompanies: (state) => {
			state.items = undefined;
		},
		clearCompanyStatistic: (state) => {
			state.activeCompanyStatistic = undefined;
		},
	},
});

export const getAvailableCompanies = () => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const response = await companiesApi.getUserAvailableCompanies();
		dispatch(getAvailableCompaniesSuccess(response));

		return response;
	} catch (error) {
		dispatch(getAvailableCompaniesFailed());

		return error;
	} finally {
		dispatch(loaded());
	}
};

export const getCompanyById = (id: string) => async () => {
	try {
		return await companiesApi.getCompanyById(id);
	} catch (error) {
		return error;
	}
};

export const editCompany =
	(companyId: string, data: Partial<CompanyInterface>) => async (dispatch: Dispatch<any>) => {
		dispatch(loading());

		return companiesApi
			.editCompany(companyId, data)
			.then(() => {
				return dispatch(getAvailableCompanies()) // @ts-ignore
					.then(() => {
						dispatch(setCompaniesErrors([]));
						dispatch(loaded());
					})
					.catch((error: any) => {
						console.log(error.errors);
					});
			})
			.catch((error: any) => {
				dispatch(setCompaniesErrors(error.errors));
				dispatch(loaded());
				return error;
			});
	};

export const editCompanyUploadImages =
	(companyId: string, data: Partial<CompanyInterface>, target: string) =>
	async (dispatch: Dispatch<any>, getState: () => RootStateType) => {
		dispatch(imageLoading({ target }));

		return companiesApi.editCompanyUploadImages(companyId, data).then(() => {
			if (getState().user.authorized) {
				dispatch(getAvailableCompanies());
			}
			dispatch(imageLoading({ target, remove: true }));
		});
	};

export const editCompanyDeleteImages =
	(companyId: string | number, target: string) => async (dispatch: Dispatch<any>) => {
		dispatch(imageLoading({ target }));

		return companiesApi.editCompanyDeleteImages(companyId, target).then(() => {
			// @ts-ignore
			return dispatch(getAvailableCompanies()).then(() => {
				dispatch(imageLoading({ target, remove: true }));
			});
		});
	};

export const createCompany = (data: CreateCompanyInterface) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		return await companiesApi.createCompany(data);
	} catch (error) {
		return error;
	} finally {
		dispatch(loaded());
	}
};

export const getCompanyStatistic = (companyId: string) => async (dispatch: Dispatch<any>) => {
	dispatch(loading());

	try {
		const response = await companiesApi.getCompanyStatistic(companyId);
		dispatch(getCompanyStatisticSuccess(response));
	} catch (error) {
		dispatch(getCompanyStatisticFailed(error.toString()));
	}
};

export const getCommunicationTemplates =
	(companyId: string | number, excludeTemplateIds?: string) =>
	async (dispatch: Dispatch<any>) => {
		dispatch(loading());

		try {
			return await companiesApi.getCommunicationTemplates(companyId, excludeTemplateIds);
		} catch (error) {
			return error;
		} finally {
			dispatch(loaded());
		}
	};

export const getCommunicationTemplateById =
	(templateId: string, companyId: string | number) => async () => {
		try {
			return await companiesApi.getCommunicationTemplateById(templateId, companyId);
		} catch (error) {
			return error;
		}
	};

export const editCommunicationTemplate =
	(templateId: string, data: Partial<EmailTemplateType>) => async () => {
		try {
			return await companiesApi.editCommunicationTemplate(templateId, data);
		} catch (error) {
			return error;
		}
	};

export const sendMessageToCandidates = (data: MessagingDataType[]) => async () => {
	try {
		return await companiesApi.sendMessageToCandidates(data);
	} catch (error) {
		return { failed: true, error };
	}
};

export const addCredits =
	(companyId: string | number, data: CreditsRefillInterface) =>
	async (dispatch: Dispatch<any>) => {
		dispatch(creditsRefillLoading(true));

		try {
			const result = await companiesApi.addCredits(companyId, data);
			dispatch(creditsRefillLoading(false));

			return result;
		} catch (error) {
			dispatch(creditsRefillLoading(false));
			throw new Error();
		}
	};

export const getActiveCompanyBalance =
	(companyId: string | number) => async (dispatch: Dispatch<any>) => {
		dispatch(balanceUpdateLoading(true));

		try {
			const result = await companiesApi.getCompanyById(companyId);

			if (result && result.balance) {
				const formattedNumber = Number(formatStringAsAbsDecimal(result.balance, false));

				dispatch(getCompanyBalanceSuccess(formattedNumber));
				dispatch(balanceUpdateLoading(false));
				return formattedNumber;
			}
		} catch (error) {
			dispatch(getCompanyBalanceSuccess(0));
			dispatch(balanceUpdateLoading(false));
			return 0;
		}
	};

export const getActiveCompanyPrepaidJobsBalance =
	(companyId: string | number) => async (dispatch: Dispatch<any>) => {
		dispatch(prepaidJobsBalanceUpdateLoading(true));

		try {
			const result = await companiesApi.getCompanyById(companyId);

			if (result && result.jobBalance) {
				const formattedNumber = Number(result.jobBalance);

				dispatch(getCompanyPrepaidJobsBalanceSuccess(formattedNumber));
				dispatch(prepaidJobsBalanceUpdateLoading(false));
				return formattedNumber;
			}
		} catch (error) {
			dispatch(getCompanyPrepaidJobsBalanceSuccess(0));
			dispatch(prepaidJobsBalanceUpdateLoading(false));
			return 0;
		} finally {
			dispatch(prepaidJobsBalanceUpdateLoading(false));
		}
	};

export const getJobBoostsHistoryForPage = async (
	companyId: string | number,
	pageNumber: number,
	pageSize: number,
) => {
	try {
		return await companiesApi.getJobBoostsHistory(companyId, pageNumber, pageSize);
	} catch (err) {
		return [];
	}
};

export const getCreditsTransactionsHistoryForPage = async (
	companyId: string | number,
	pageNumber: number,
	pageSize: number,
) => {
	try {
		return await companiesApi.getTransactionsHistory(companyId, pageNumber, pageSize);
	} catch (err) {
		return [];
	}
};

export const getPaymentTransactionsHistoryForPage = async (
	companyId: string | number,
	pageNumber: number,
	pageSize: number,
) => {
	try {
		return await companiesApi.getPaymentTransactionsHistory(companyId, pageNumber, pageSize);
	} catch (err) {
		return [];
	}
};

export const {
	loading,
	loaded,
	imageLoading,
	clearCompanies,
	setActiveCompany,
	setCompaniesErrors,
	clearActiveCompany,
	getCompanyStatisticSuccess,
	getCompanyStatisticFailed,
	getAvailableCompaniesSuccess,
	getCompanyBalanceSuccess,
	getCompanyPrepaidJobsBalanceSuccess,
	clearCompanyStatistic,
	getAvailableCompaniesFailed,
	creditsRefillLoading,
	balanceUpdateLoading,
	prepaidJobsBalanceUpdateLoading,
} = companiesSlice.actions;
export default companiesSlice.reducer;
