import { useFeatureValue } from '@growthbook/growthbook-react';

const useFeatureFlagValue = (flagKey: string, valueType?: string) => {
	let fallbackValue: any;

	switch (valueType) {
		case 'string':
			fallbackValue = '';
			break;
		case 'number':
			fallbackValue = 0;
			break;
		default:
			//boolean
			fallbackValue = false;
			break;
	}

	const flagValue = useFeatureValue(flagKey, fallbackValue);

	if (flagValue === undefined || flagValue === null) return fallbackValue;

	return flagValue;
};

export default useFeatureFlagValue;
